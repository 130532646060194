<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.riferimento_interno, row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>

      <b-row v-if="!noPagination">
        <div class="show-text">
          <p>Mostra</p>
        </div>

        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>

        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import BaseInputPagination from "@/components/form/BaseInputPagination";
import { toLocaleCurrency } from "@/utils/strings";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  name: "ReducedDeferredCashEntryTable",
  extends: template,
  data() {
    return {
      args: null,
      modalResourceId: null,
      tabIndex: 0,
    };
  },
  beforeMount() {
    this.args = this.$parent.$parent.args;
  },
  components: {
    BaseIcon,
    BaseInputPagination,
  },
  methods: {
    toLocaleCurrency,
    onDestroy(idSospeso, id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.cancellaRecupero(idSospeso, id)
        .then(() => {
          this.$emit("parentFetch");
          this.$showSnackbar({
            preset: "success",
            text: `Recupero eliminato con successo`,
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
  },
};
</script>

<style lang="css" scoped></style>
